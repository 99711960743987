<template>
  <section class="company-intro flex-container">
    <div class="company-intro__items">
      <div class="img-placeholder">
        <img v-if="logoURL" :src="logoURL" alt="Logo" class="company-logo" />
      </div>
      <div class="company-intro__item">
        <h2 class="company-intro__title">
          {{ $t('COMPANY_INTRODUCTION_TITTLE') }}
        </h2>
        <p class="company-intro__subtitle paragraph">
          {{ $t('COMPANY_INTRODUCTION_SUBTITTLE_1') }}
          <span class="text-bold">
            {{ $t('COMPANY_INTRODUCTION_SUBTITTLE_2') }}
          </span>
          {{ $t('COMPANY_INTRODUCTION_SUBTITTLE_3') }}
        </p>

        <s-link
          btn-style="sea"
          class="company-intro__button"
          @click.prevent="$emit('dismiss')"
        >
          {{ $t('COMPANY_INTRODUCTION_SUBTITLE_BUTTON') }}
        </s-link>
      </div>

      <div class="company-intro__item">
        <div class="paragraph">
          <p class="text-bold">
            {{ $t('COMPANY_INTRO_LATERAL_BOLD') }}
          </p>

          <p>{{ $t('COMPANY_INTRO_LATERAL_TEXT_1') }}.</p>

          <p>
            {{ $t('COMPANY_INTRO_LATERAL_TEXT_2') }}
            <router-link :to="issuesRoute">
              {{ $t('COMPANY_INTRO_LATERAL_LINK_1') }}
            </router-link>
          </p>

          <p>
            {{ $t('COMPANY_INTRO_LATERAL_TEXT_3') }}
            <a href="mailto:suporte@safe.space">suporte@safe.space</a>
          </p>
        </div>
      </div>
    </div>

    <div class="company-intro__items sm-hide">
      <div class="company-intro__item">
        <div class="company-intro__card">
          <img :src="companyWelcomeImg" alt="" />
          <span class="company-intro__item-text">
            <p>{{ $t('COMPANY_INTRO_IMAGE_TEXT') }}</p>
            <ul class="company-intro__list">
              <li class="company-intro__list__item">
                {{ $t('COMPANY_INTRO_LIST_1') }}
              </li>
              <li class="company-intro__list__item">
                {{ $t('COMPANY_INTRO_LIST_2') }}
              </li>
              <li class="company-intro__list__item">
                {{ $t('COMPANY_INTRO_LIST_3') }}
              </li>
              <li class="company-intro__list__item">
                {{ $t('COMPANY_INTRO_LIST_4') }}
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import companyWelcomeImg from '@/assets/images/company_welcome_ilustration.svg'
import SLink from '../../components/ui/s-link'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CompanyIntroduction',

  components: {
    SLink,
  },

  data() {
    const { slug, slugId } = this.$route.params

    return {
      slug,
      slugId,
      companyWelcomeImg,
      logoURL: null,
    }
  },

  computed: {
    ...mapState(['company']),

    issuesRoute() {
      return {
        name: 'issues',
        params: {
          slug: this.slug,
          slugId: this.slugId,
          agree: true,
        },
      }
    },
  },

  async mounted() {
    const { logoImg } = this.company

    if (logoImg) {
      this.logoURL = await this.getCompanyLogo(logoImg)
    }
  },

  methods: mapActions(['getCompanyLogo']),
}
</script>

<style scoped>
.company-intro {
  color: var(--color-black);
  width: 65%;
  margin: 0 auto;
}

.company-intro.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-intro .company-logo {
  max-width: 200px;
  max-width: 200px;
}
.company-intro .img-placeholder {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.company-intro__title {
  color: var(--color-black);
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 1.2;
}
.company-intro__subtitle {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
}
.company-intro__button {
  display: block;
  width: fit-content;
  margin-top: 2rem;
}

.company-intro__item .paragraph {
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 450px;
}

.company-intro a {
  text-decoration: underline;
}
.company-intro__list__item {
  font-weight: 600;
  padding-bottom: 5px;
}

.company-intro__list {
  padding: 0 0 0 10px;
  list-style-type: disc;
}

.company-intro__card {
  border-radius: 25px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  margin-top: 5rem;
  margin-left: 5rem;
  width: 380px;
}

.company-intro__item-text {
  padding: 10px;
  padding-left: 30px;
  text-align: left;
  display: block;
  font-size: 16px;
}

.text-bold {
  font-weight: 600;
}

.sm-hide {
  display: block;
}

@media (max-width: 768px) {
  .company-intro {
    width: 75%;
  }

  .company-intro .img-placeholder {
    height: 200px;
  }

  .company-intro__title {
    font-size: 2rem;
    line-height: 1.1;
  }

  .company-intro__subtitle {
    font-size: 16px;
    line-height: 20px;
  }
  .company-intro__button {
    margin-bottom: 30px;
    width: 100%;
  }

  .sm-hide {
    display: none;
  }

  .company-intro__items {
    flex-wrap: wrap;
  }
}
</style>
