<template>
  <main>
    <CompanyIntroduction
      v-if="showIntroduction"
      @dismiss="showIntroduction = false"
    />

    <section v-if="!showIntroduction" class="company-root">
      <div class="company-root__head">
        <img class="icon-locker" :src="lockerIcon" alt="SafeSpace" />
        <s-title>SafeSpace</s-title>
      </div>

      <RelationshipField
        :company="company"
        @submit-source="(source) => submitSource(source)"
      />

      <Agreements v-model="agreement" align="left" />

      <OptionsContainer
        v-if="displayOptions"
        :is-disclosure-enabled="displayDisclosure"
        :is-policies-enabled="displayPolicies"
        :is-issues-enabled="isIssueEnabled"
        @next-step="nextStep"
      />
    </section>

    <CompanyLoading v-if="loading" />
  </main>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import router from '../../plugins/router'
import CompanyLoading from './CompanyLoading'
import CompanyIntroduction from '@/components/CompanyRoot/CompanyIntroduction'
import RelationshipField from '@/components/CompanyRoot/RelationshipField'
import OptionsContainer from '@/components/CompanyRoot/OptionsContainer'
import Agreements from '@/components/Agreements'
import STitle from '@/components/ui/s-title'
import lockerIcon from '@/assets/images/icone_locker.svg'

export default {
  name: 'CompanyRoot',

  components: {
    Agreements,
    STitle,
    CompanyLoading,
    CompanyIntroduction,
    RelationshipField,
    OptionsContainer,
  },

  data() {
    return {
      lockerIcon,
      agreement: false,
      source: '',
      showIntroduction: false,
    }
  },

  computed: {
    ...mapState(['company', 'loading', 'error']),
    ...mapGetters([
      'isFeatureEnabled',
      'isSourceCollaborator',
      'isIssueFeatureEnabled',
    ]),

    displayOptions() {
      const isCompanyRelationship =
        this.source !== '' && this.source !== null && this.source !== undefined

      return isCompanyRelationship && this.agreement === true
    },

    displayDisclosure() {
      return (
        this.displayOptions &&
        this.isSourceCollaborator(this.source) &&
        this.isFeatureEnabled('disclosure')
      )
    },

    displayPolicies() {
      return (
        this.displayOptions &&
        this.isSourceCollaborator(this.source) &&
        this.isFeatureEnabled('documentsManagement')
      )
    },

    isIssueEnabled() {
      return this.displayOptions && this.isIssueFeatureEnabled
    },
  },

  watch: {
    error() {
      if (this.error === 'NOT_FOUND') {
        router.push('/404')
      }

      if (this.error === 'INACTIVE_COMPANY') {
        router.push('/')
      }
    },
  },

  async mounted() {
    const { id, slug, slugId } = router.currentRoute.params

    await this.getCompanyBySlug({ id, slug, slugId })

    this.showIntroduction = this.isFeatureEnabled('introductionPage')
  },

  methods: {
    ...mapActions(['getCompanyBySlug']),
    ...mapMutations(['mutate']),

    submitSource(source) {
      this.source = source
    },

    nextStep(step) {
      const params = {
        agree: this.agreement,
        source: this.source,
        slug: this.company.slug,
        slugId: this.company.slugId,
      }

      const stepsName = {
        report: 'company-home',
        issue: 'issues',
        disclosure: 'company-disclosure',
        policies: 'company-policies',
      }

      this.mutate({ property: 'relationship', value: this.source })
      this.mutate({ property: 'loading', value: false })

      router.push({ params, name: stepsName[step] })
    },
  },
}
</script>
<style scoped>
main {
  min-height: var(--default-height-desktop);
  background-color: var(--color-white-soft);
}
.company-root {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  width: 450px;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

@media (min-width: 320px) {
  .company-root {
    padding: 0 25px;
  }
}

.company-root__head {
  display: flex;
  align-items: center;
  margin: 50px auto;
  gap: 12px;
}

.company-root__head .title {
  display: inline-block;
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 1.15;
}

.icon-locker {
  margin-right: 1.25rem;
  transform: scale(3);
  -webkit-transform: scale(3);
  vertical-align: super;
}
</style>
