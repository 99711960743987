<template>
  <div class="company-relationship">
    <h3 class="company-title">
      {{ $t('REPORT_COMPANY_RELATIONSHIP_TITLE') }}
      <span v-if="!loading" class="company-name">{{ company.name }}</span>
      ?
    </h3>

    <CompanyRelationshipField v-model="source" :relations="enabledRelations" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'RelationshipField',

  components: {
    CompanyRelationshipField: () =>
      import('@/components/ReportFields/CompanyRelationshipField'),
  },

  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      source: '',
    }
  },

  computed: {
    ...mapState(['loading']),
    ...mapGetters(['enabledRelations']),
  },

  watch: {
    source() {
      this.$emit('submit-source', this.source)
    },
  },
}
</script>
<style scoped>
.company-relationship .company-title {
  color: var(--color-blue-default);
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 28px;
}

.company-title .company-name {
  text-decoration: underline;
}
</style>
