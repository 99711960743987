<template>
  <div class="options-container">
    <OptionCard color="blue" @next-step="$emit('next-step', 'report')">
      <template #icon><img :src="ReportIcon" alt="" /></template>
      <template #content>
        <p>{{ $t('COMPANY_ROOT.REPORT_TITLE') }}</p>
        <p>{{ $t('COMPANY_ROOT.REPORT_DESCRIPTION') }}</p>
      </template>
    </OptionCard>
    <OptionCard
      v-if="isDisclosureEnabled"
      @next-step="$emit('next-step', 'disclosure')"
    >
      <template #icon><img :src="DisclosureIcon" alt="" /></template>
      <template #content>
        <p>{{ $t('COMPANY_ROOT.DISCLOSURE_TITLE') }}</p>
        <p>{{ $t('COMPANY_ROOT.DISCLOSURE_DESCRIPTION') }}</p>
      </template>
    </OptionCard>
    <OptionCard v-if="isIssuesEnabled" @next-step="$emit('next-step', 'issue')">
      <template #icon><img :src="IssueIcon" alt="" /></template>
      <template #content>
        <p>{{ $t('COMPANY_ROOT.ISSUE_TITLE') }}</p>
        <p>{{ $t('COMPANY_ROOT.ISSUE_DESCRIPTION') }}</p>
      </template>
    </OptionCard>
    <OptionCard
      v-if="isPoliciesEnabled"
      @next-step="$emit('next-step', 'policies')"
    >
      <template #icon><img :src="PolicyIcon" alt="" /></template>
      <template #content>
        <p>{{ $t('COMPANY_ROOT.POLICIES_TITLE') }}</p>
        <p>{{ $t('COMPANY_ROOT.POLICIES_DESCRIPTION') }}</p>
      </template>
    </OptionCard>
  </div>
</template>

<script>
import OptionCard from '@/components/OptionCard'
import DisclosureIcon from '@/assets/images/company_root/disclosure.svg'
import IssueIcon from '@/assets/images/company_root/issue.svg'
import ReportIcon from '@/assets/images/company_root/report.svg'
import PolicyIcon from '@/assets/images/policy/policy_icon.svg'

export default {
  name: 'CompaniesOptionsContainer',

  components: {
    OptionCard,
  },

  props: {
    isDisclosureEnabled: {
      type: Boolean,
      default: false,
    },
    isPoliciesEnabled: {
      type: Boolean,
      default: false,
    },
    isIssuesEnabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      DisclosureIcon,
      IssueIcon,
      ReportIcon,
      PolicyIcon,
    }
  },
}
</script>

<style scoped>
.options-container {
  margin-top: 15px;
}
</style>
